<template>
  <el-row>
      <fixed-item></fixed-item>
    <el-col class="cloud-doc" :xs="0" :sm="24" :md="24" :lg="24" :xl="24">
      <el-col class="module module-top">
        <el-col class="page-title">伊登云·跨平台</el-col>
        <el-col class="product">文档安全管理系统</el-col>
<!--        <el-col class="trial-btn">-->
<!--          <el-col class="btn" @click.native="tryNow">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>-->
<!--        </el-col>-->
      </el-col>
      <el-col class="module doc-information">
        <el-col class="doc-interface">
          <img src="@/img/product/cloudDoc/doc_interface.png">
        </el-col>
        <el-col class="doc-info">
          伊登文档安全管理系统，高效、便捷、安全、协同、合规，专注文档管控和内容管理！为企业提供统一文档办公平台，为企业业务系统提供文档内容服务。
        </el-col>
        <el-col class="doc-fun">
          随时随地  高效协作办公
        </el-col>
<!--        <el-col class="trial-btn">-->
<!--          <el-col class="btn" @click.native="tryNow">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>-->
<!--        </el-col>-->
        <el-col class="doc-scene">
          <el-col class="scene-item" :xs="15" :sm="15" :md="15" :lg="15" :xl="15">
            <el-col class="scene-introduce title-left" :xs="0" :sm="10" :md="10" :lg="10" :xl="10">
              <el-col class="introduce-title">
                多人实时在线编辑，协作动态实时可见
              </el-col>
              <el-col class="introduce-des">
                您可以和同事一起在线编辑文档、表格，并随时查看修改记录和历史版本。
              </el-col>
            </el-col>
            <el-col class="img-right" :xs="0" :sm="12" :md="11" :lg="12" :xl="12">
              <img src="@/img/product/cloudDoc/wendang_1.png" style="height: 100%;width: 100%;">
            </el-col>

          </el-col>
          <el-col class="scene-item item" :xs="15" :sm="15" :md="15" :lg="15" :xl="15">
            <el-col class="introduce-img img-left" :xs="0" :sm="10" :md="10" :lg="10" :xl="10">
              <img src="@/img/product/cloudDoc/wendang_2.png" style="height: 100%;width: 100%;">
            </el-col>
            <el-col class="scene-introduce title-right" :xs="0" :sm="12" :md="12" :lg="12" :xl="12">
              <el-col class="introduce-title">
                互动交流，文档评论
              </el-col>
              <el-col class="introduce-des">
                基于项目或文档，需要与同事展示互动交流时，便于意见收集。
              </el-col>
            </el-col>

          </el-col>
          <el-col class="scene-item" :xs="15" :sm="15" :md="15" :lg="15" :xl="15">

            <el-col class="scene-introduce scene-white title-left" :xs="0" :sm="10" :md="10" :lg="10" :xl="10">
              <el-col class="introduce-title">
                轻松实现文件存储共享、外发分享
              </el-col>
              <el-col class="introduce-des">
                强大地文件同步功能，内部员工文档协同可通过共享操作轻松同步，外部员工文档传送可通过分享操作快捷传输。
              </el-col>
            </el-col>
            <el-col class="introduce-img img-right" :xs="0" :sm="12" :md="11" :lg="12" :xl="12">
              <img src="@/img/product/cloudDoc/wendang_3.png" style="height: 100%;width: 100%;">
            </el-col>

          </el-col>
        </el-col>
        <el-col class="doc-scene-out">

          <el-col class="scene-item" :xs="15" :sm="15" :md="15" :lg="15" :xl="15">
            <el-col class="introduce-img img-left" :xs="0" :sm="10" :md="10" :lg="10" :xl="10">
              <img src="@/img/product/cloudDoc/wendang_4.png" style="height: 100%;width: 100%;">
            </el-col>
            <el-col class="scene-introduce scene-white title-right" :xs="0" :sm="12" :md="12" :lg="12" :xl="12">
              <el-col class="introduce-title">
                多人实时在线编辑，协作动态实时可见
              </el-col>
              <el-col class="introduce-des">
                您可以和同事一起在线编辑文档、表格，并随时查看修改记录和历史版本。
              </el-col>
            </el-col>


          </el-col>

        </el-col>
      </el-col>
      <el-col class="module doc-security">
        <el-col class="security-title">
          值得信赖的文件存储与传输安全
        </el-col>
<!--        <el-col class="trial-btn">-->
<!--          <el-col class="btn" @click.native="tryNow">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>-->
<!--        </el-col>-->
        <el-col class="security-des" :xl="15" :lg="18">
          <el-col class="des-item" :xl="5" :lg="5">
            <el-col class="des-title">
              01 访问安全
            </el-col>
            <el-col class="security-item">
              <el-col class="item">访问身份验证</el-col>
              <el-col class="item">IP访问控制</el-col>
              <el-col class="item">登录设备控制</el-col>
              <el-col class="item">文档数字水印访问</el-col>
            </el-col>
          </el-col>
          <el-col class="des-item" :xl="5" :lg="5">
            <el-col class="des-title">
              01 数据安全
            </el-col>
            <el-col class="security-item">
              <el-col class="item">防勒索病毒系统集成</el-col>
              <el-col class="item">切片传输技术应用</el-col>
              <el-col class="item">数据二次保护</el-col>
              <el-col class="item">个人数据与团队数据隔离</el-col>
            </el-col>
          </el-col>
          <el-col class="des-item" :xl="5" :lg="5">
            <el-col class="des-title">
              01 安全监督
            </el-col>
            <el-col class="security-item">
              <el-col class="item">数据统计报表</el-col>
              <el-col class="item">系统异常报警</el-col>
              <el-col class="item">操作行为跟踪</el-col>
              <el-col class="item">数据外发监管</el-col>
            </el-col>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="module doc-case">
        <el-col class="case-title">
          多平台，多设备，满足多种办公场景
        </el-col>
        <el-col class="case-list" :span="12">
          <el-col class="case-item" :span="3">
            <el-col :span="24" class="item-img">
              <img src="@/img/product/cloudDoc/ic_web.png">
            </el-col>
            <el-col :span="24" class="item-content">
              web端
            </el-col>
          </el-col>
          <el-col class="case-item" :span="3">
            <el-col :span="24" class="item-img">
              <img src="@/img/product/cloudDoc/ic_kehu.png">
            </el-col>
            <el-col :span="24" class="item-content">
              客户端
            </el-col>
          </el-col>
          <el-col class="case-item" :span="3">
            <el-col :span="24" class="item-img">
              <img src="@/img/product/cloudDoc/ic_yidongduan.png">
            </el-col>
            <el-col :span="24" class="item-content">
              移动端
            </el-col>
          </el-col>
          <el-col class="case-item" :span="3">
            <el-col :span="24" class="item-img">
              <img src="@/img/product/cloudDoc/ic_yingshe.png">
            </el-col>
            <el-col :span="24" class="item-content">
              映射盘
            </el-col>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="module doc-manager">
        <el-col class="manager-title">
          科学地文档集中管理
        </el-col>
        <el-col class="manager-list" :xs="12" :sm="16" :md="16" :lg="16" :xl="16">
          <el-col class="item item_1 relative-style">
              <img src="../../img/product/cloudDoc/kexue_1.png" class="item-img-kexue">
              <el-col class="title absolute-style">
                权限管理
              </el-col>
              <el-col class="content absolute-style text-overflow-cloudDoc">
                6大模块细分55项权限设置
                既支持对单个文件进行授权，也支持对单个用户/角色进行授权
              </el-col>
          </el-col>
          <el-col class="item item_2 relative-style">
            <img src="../../img/product/cloudDoc/kexue_2.png" class="item-img-kexue">
            <el-col class="title absolute-style">
              多级权限管控
            </el-col>
            <el-col class="content absolute-style text-overflow-cloudDoc">
              支持多级权限分配，可设置集团公司总管理员，对所有单位的使用具有管理权限；
              子公司设置管理员，对子公司的使用具有管理权限；部门设置子管理员，对部门的使用具有管理权限。
            </el-col>
          </el-col>

         <el-col class="item item_3 relative-style">
            <img src="../../img/product/cloudDoc/kexue_3.png" class="item-img-kexue">
            <el-col class="title absolute-style">
              流程管理
            </el-col>
            <el-col class="content absolute-style text-overflow-cloudDoc">
              灵活自定义多种审批流程，系统支持外发分享审批。
            </el-col>
          </el-col>
          <el-col class="item item_4 relative-style">
            <img src="../../img/product/cloudDoc/kexue_4.png" class="item-img-kexue">
            <el-col class="title absolute-style">
              用户管理
            </el-col>
            <el-col class="content absolute-style text-overflow-cloudDoc">
              Local AD域同步，支持单个，或者多个AD域同步，支持一个AD域中单个或者多个OU同步，可以设置同步频率，树形选择某几个OU进行同步。
            </el-col>
          </el-col>
          <el-col class="item item_5 relative-style">
            <img src="../../img/product/cloudDoc/kexue_5.png" class="item-img-kexue">
            <el-col class="title absolute-style">
              可视化
              多维度数据报表
            </el-col>
            <el-col class="content absolute-style text-overflow-cloudDoc">
              系统提供丰富的统计报表和简单直观的展示界面，管理员可以宏观了解企业文档使用情况，以及员工上传使用情况。
            </el-col>
          </el-col>
        </el-col>
      </el-col>
    </el-col>

    <el-col class="cloud-doc" :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
      <el-col class="module module-top">
        <el-col class="page-title">伊登云·跨平台</el-col>
        <el-col class="product">文档安全管理系统</el-col>
<!--        <el-col class="trial-btn">-->
<!--          <el-col class="btn" @click.native="tryNow">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>-->
<!--        </el-col>-->
      </el-col>
      <el-col class="module doc-information">
        <el-col class="doc-interface">
          <img src="@/img/product/cloudDoc/doc_interface.png">
        </el-col>
        <el-col class="doc-info">
          伊登文档安全管理系统，高效、便捷、安全、协同、合规，专注文档管控和内容管理！为企业提供统一文档办公平台，为企业业务系统提供文档内容服务。
        </el-col>
        <el-col class="doc-fun">
          随时随地  高效协作办公
        </el-col>
<!--        <el-col class="trial-btn">-->
<!--          <el-col class="btn" @click.native="tryNow">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>-->
<!--        </el-col>-->

        <el-col class="doc-scene">
          <el-col class="scene-item" :xs="24" :sm="15" :md="15" :lg="15" :xl="15">

            <el-col class="introduce-title">
              多人实时在线编辑，协作动态实时可见
            </el-col>

            <el-col class="scene-introduce title-left" :xs="12" :sm="10" :md="10" :lg="10" :xl="10">

              <el-col class="introduce-des">
                您可以和同事一起在线编辑文档、表格，并随时查看修改记录和历史版本。
              </el-col>
            </el-col>
            <el-col class="img-right" :xs="8" :sm="12" :md="11" :lg="12" :xl="12">
              <img src="@/img/product/cloudDoc/wendang_1.png" style="height: 100%;width: 100%;">
            </el-col>

          </el-col>
          <el-col class="scene-item item" :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
            <el-col class="introduce-title">
              互动交流，文档评论
            </el-col>
            <el-col class="introduce-img img-left" :xs="8" :sm="10" :md="10" :lg="10" :xl="10">
              <img src="@/img/product/cloudDoc/wendang_2.png" style="height: 100%;width: 100%;">
            </el-col>
            <el-col class="scene-introduce title-right" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">

              <el-col class="introduce-des">
                基于项目或文档，需要与同事展示互动交流时，便于意见收集。
              </el-col>
            </el-col>

          </el-col>
          <el-col class="scene-item" :xs="24" :sm="15" :md="15" :lg="15" :xl="15">

            <el-col class="introduce-title">
              轻松实现文件存储共享、外发分享
            </el-col>
            <el-col class="scene-introduce scene-white title-left" :xs="11" :sm="10" :md="10" :lg="10" :xl="10">

              <el-col class="introduce-des" style="color: #FFFFFF">
                强大地文件同步功能，内部员工文档协同可通过共享操作轻松同步，外部员工文档传送可通过分享操作快捷传输。
              </el-col>
            </el-col>
            <el-col class="introduce-img img-right" :xs="9" :sm="12" :md="11" :lg="12" :xl="12">
              <img src="@/img/product/cloudDoc/wendang_3.png" style="height: 100%;width: 100%;">
            </el-col>

          </el-col>
        </el-col>
      <el-col class="doc-scene-out">

          <el-col class="scene-item" :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
            <el-col class="introduce-title2">
              多人实时在线编辑，协作动态实时可见
            </el-col>

            <el-col class="introduce-img img-left" :xs="8" :sm="10" :md="10" :lg="10" :xl="10">
              <img src="@/img/product/cloudDoc/wendang_4.png" style="height: 100%;width: 100%;">
            </el-col>
            <el-col class="scene-introduce scene-white title-right" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">

              <el-col class="introduce-des">
                您可以和同事一起在线编辑文档、表格，并随时查看修改记录和历史版本。
              </el-col>
            </el-col>
          </el-col>

      </el-col>
      </el-col>
      <el-col class="module doc-security">
        <el-col class="security-title">
          值得信赖的文件存储与传输安全
        </el-col>
<!--        <el-col class="trial-btn">-->
<!--          <el-col class="btn" @click.native="tryNow">在线试用<img src="../../img/common/right_arrow_blue.svg"></el-col>-->
<!--        </el-col>-->
        <el-col class="security-des" :xl="15" :lg="18">
          <el-col class="des-item" :xl="5" :lg="5">
            <el-col class="des-title">
              01 访问安全
            </el-col>
            <el-col class="security-item">
              <el-col class="item">访问身份验证</el-col>
              <el-col class="item">IP访问控制</el-col>
              <el-col class="item">登录设备控制</el-col>
              <el-col class="item">文档数字水印访问</el-col>
            </el-col>
          </el-col>
          <el-col class="des-item" :xl="5" :lg="5">
            <el-col class="des-title">
              01 数据安全
            </el-col>
            <el-col class="security-item">
              <el-col class="item">防勒索病毒系统集成</el-col>
              <el-col class="item">切片传输技术应用</el-col>
              <el-col class="item">数据二次保护</el-col>
              <el-col class="item">个人数据与团队数据隔离</el-col>
            </el-col>
          </el-col>
          <el-col class="des-item" :xl="5" :lg="5">
            <el-col class="des-title">
              01 安全监督
            </el-col>
            <el-col class="security-item">
              <el-col class="item">数据统计报表</el-col>
              <el-col class="item">系统异常报警</el-col>
              <el-col class="item">操作行为跟踪</el-col>
              <el-col class="item">数据外发监管</el-col>
            </el-col>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="module doc-case">
        <el-col class="case-title">
          多平台，多设备，满足多种办公场景
        </el-col>
        <el-col class="case-list" :span="20">
          <el-col class="case-item" :span="4">
            <el-col :span="24" class="item-img">
              <img src="@/img/product/cloudDoc/ic_web.png">
            </el-col>
            <el-col :span="24" class="item-content">
              web端
            </el-col>
          </el-col>
          <el-col class="case-item" :span="4">
            <el-col :span="24" class="item-img">
              <img src="@/img/product/cloudDoc/ic_kehu.png">
            </el-col>
            <el-col :span="24" class="item-content">
              客户端
            </el-col>
          </el-col>
          <el-col class="case-item" :span="4">
            <el-col :span="24" class="item-img">
              <img src="@/img/product/cloudDoc/ic_yidongduan.png">
            </el-col>
            <el-col :span="24" class="item-content">
              移动端
            </el-col>
          </el-col>
          <el-col class="case-item" :span="4">
            <el-col :span="24" class="item-img">
              <img src="@/img/product/cloudDoc/ic_yingshe.png">
            </el-col>
            <el-col :span="24" class="item-content">
              映射盘
            </el-col>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="module doc-manager">
        <el-col class="manager-title">
          科学地文档集中管理
        </el-col>
        <el-col class="manager-list" :xs="20" :sm="16" :md="16" :lg="16" :xl="16">
          <el-col class="item item_1 relative-style">
            <img src="../../img/product/cloudDoc/kexue_1.png" class="item-img-kexue">
            <el-col class="title absolute-style">
              权限管理
            </el-col>
            <el-col class="content absolute-style text-overflow-cloudDoc">
              6大模块细分55项权限设置
              既支持对单个文件进行授权，也支持对单个用户/角色进行授权
            </el-col>
          </el-col>
          <el-col class="item item_2 relative-style">
            <img src="../../img/product/cloudDoc/kexue_2.png" class="item-img-kexue">
            <el-col class="title absolute-style">
              多级权限管控
            </el-col>
            <el-col class="content absolute-style text-overflow-cloudDoc">
              支持多级权限分配，可设置集团公司总管理员，对所有单位的使用具有管理权限；
              子公司设置管理员，对子公司的使用具有管理权限；部门设置子管理员，对部门的使用具有管理权限。
            </el-col>
          </el-col>

          <el-col class="item item_3 relative-style">
            <img src="../../img/product/cloudDoc/kexue_3.png" class="item-img-kexue">
            <el-col class="title absolute-style">
              流程管理
            </el-col>
            <el-col class="content absolute-style text-overflow-cloudDoc">
              灵活自定义多种审批流程，系统支持外发分享审批。
            </el-col>
          </el-col>
          <el-col class="item item_4 relative-style">
            <img src="../../img/product/cloudDoc/kexue_4.png" class="item-img-kexue">
            <el-col class="title absolute-style">
              用户管理
            </el-col>
            <el-col class="content absolute-style text-overflow-cloudDoc">
              Local AD域同步，支持单个，或者多个AD域同步，支持一个AD域中单个或者多个OU同步，可以设置同步频率，树形选择某几个OU进行同步。
            </el-col>
          </el-col>
          <el-col class="item item_5 relative-style">
            <img src="../../img/product/cloudDoc/kexue_5.png" class="item-img-kexue">
            <el-col class="title absolute-style">
              可视化
              多维度数据报表
            </el-col>
            <el-col class="content absolute-style text-overflow-cloudDoc">
              系统提供丰富的统计报表和简单直观的展示界面，管理员可以宏观了解企业文档使用情况，以及员工上传使用情况。
            </el-col>
          </el-col>
        </el-col>
      </el-col>
    </el-col>

    <el-col class="trial-back" style="position: relative;">
      <img src="../../img/product/cloudDoc/bg_2.png" style="height: 100%;width: 100%">
<!--      <el-col class="btn btn-position" @click.native="tryNow"  style="">在线试用</el-col>-->
    </el-col>
  </el-row>
</template>

<script>


import FixedItem from "../../compoents/fixed/fixedItem";

export default {
    name: "cloudDoc",
    components: {FixedItem},
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
    tryNow() {
      this.$store.commit('setCheckIndex', 2);
      // this.$store.state.common.checkIndex = 2;
      this.$router.push({path: '/GoodsDescription'})
    }
  }
}
</script>

<style lang="less" scoped>




@media screen and (max-width: 575px) {
  .text-overflow-cloudDoc{
    word-break: keep-all;
    /*white-space: nowrap;*/
    overflow: hidden;
    text-overflow:ellipsis;
  }

  .item-img-kexue{
    width: 100%;
    height: 350px;
  }
  .relative-style{
    position: relative;
  }
  .absolute-style{
    position: absolute;top: 0px;
  }
  .cloud-doc {
    background: #FFFFFF;
    margin-bottom: 120px;

    .module-top {
      margin-top: 96px;

      .page-title {
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        color: #3764D7;
        line-height: 67px;
      }

      .product {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #3764D7;
        line-height: 42px;
        margin-top: 24px;
      }


    }

    .doc-information {
      background: url("../../img/product/cloudDoc/Group 1475@2x.png") no-repeat top center;
      width: 100%;
      margin-top: 159px;



      .doc-interface {
        float: unset;
        margin: auto;
        position: relative;
        bottom: 111px;
        img{
          width: 100%;
          height: 100%;
        }
      }

      .doc-info {
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        float: unset;
        margin: auto;
        text-align: center;
        position: relative;
        bottom: 85px;
      }

      .introduce-title {
        height: 9vh;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 9vh;
        text-align: center;
      }

      .introduce-title2 {
        height: 9vh;
        font-size: 16px;
        font-weight: 400;
        color: #414141;
        line-height: 9vh;
        text-align: center;
      }

      .doc-fun {
        font-size: 2rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 48px;
        text-align: center;
      }

      .scene-item {
        margin: auto;
        float: unset;
        text-align: justify;
        height: 190px;

        .introduce-img {

        }

        .title-right {
          padding-right: 6vw;
        }

        .title-left {
          padding-left: 6vw;
        }

        .img-left {
          margin-right: 2vw;
          margin-left: 10vw;
        }

        .img-right {
          margin-left: 2vw;
        }
        .scene-introduce {
          padding-top: 2vw;



          .introduce-des {
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
          }
        }
        .scene-white {
          .introduce-title {
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
          }
          .introduce-des {
            color: #414141;
          }
        }
      }

      .doc-scene {
        text-align: center;

        .scene-item:first-child {
          margin-top: unset;
        }

        .scene-item {
        }
      }

      .doc-scene-out {
        text-align: center;
        margin-top: 72px;
      }
    }

    .doc-security {
      margin-top: 120px;
      .security-title {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        color: #3764D7;
        line-height: 48px;
      }

      .security-des {
        margin: auto;
        float: unset;
        height: auto;

        .des-item:last-child {
          margin-right: unset;
        }

        .des-item {
          height: 376px;
          box-shadow: 0px 8px 17px 0px rgba(171, 171, 171, 0.5);
          border-radius: 22px;
          text-align: center;
          margin-right: 10vw;
          margin-top: 48px;
          .des-title {
            font-size: 24px;
            font-weight: 600;
            color: #3764D7;
            line-height: 32px;
            padding-top: 66px;
          }

          .security-item {
            text-align: center;


            .item:first-child {
              margin-top: 32px;
            }

            .item {
              font-size: 16px;
              font-weight: 400;
              color: #414141;
              line-height: 24px;
              margin-top: 24px;
            }
          }
        }
      }
    }

    .doc-case {
      margin-top: 120px;
      .case-title {
        font-size: 1.3rem;
        font-weight: 500;
        color: #3764D7;
        line-height: 48px;
        text-align: center;
        margin-bottom: 48px;
      }
      .case-list {
        margin: auto;
        float: unset;
        height: auto;

        .case-item:last-child {
          margin-right: 0;
        }

        .case-item {
          text-align: center;
          margin-right: 8vw;
          height: auto;
          .item-content {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
            margin-top: 17px;
          }
        }
      }
    }

    .doc-manager {
      margin-top: 120px;
      .manager-title {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: #3764D7;
        line-height: 48px;
        margin-bottom: 48px;
      }

      .manager-list {
        margin: auto;
        float: unset;
        .item:hover {
          filter: brightness(50%);
          -webkit-filter: brightness(50%);
          cursor: pointer;

          .title {
            display: none;
          }

          .content {
            display: block;
          }
        }
        .item:last-child {
          margin-right: 0;
        }
        .item {
          /*margin-right: 36px;*/
          .title {
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 32px;
            text-align: center;
            //padding: 164px 56px;
            padding-top: 164px;
            display: block;
          }
          .content {
            display: none;
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 32px;
            padding: 75px 18px;
            text-align: justify;
          }
        }

        .item_1 {
          /*background: url("../../img/product/cloudDoc/kexue_1.png") no-repeat top center;*/
          height: 360px;
          width: 48%;
          margin-right: 1vw;
        }

        .item_2 {
          /*background: url("../../img/product/cloudDoc/kexue_2.png") no-repeat top center;*/
          width: 48%;
          height: 360px;
          margin-left: 1vw;
        }
        .item_3 {
          /*background: url("../../img/product/cloudDoc/kexue_3.png") no-repeat top center;*/
          width: 100%;
          height: 360px;
        }

        .item_4 {
          /*background: url("../../img/product/cloudDoc/kexue_4.png") no-repeat top center;*/
          width: 48%;
          height: 360px;
          margin-right: 1vw;
        }

        .item_5 {
          /*background: url("../../img/product/cloudDoc/kexue_5.png") no-repeat top center;*/
          width: 48%;
          height: 360px;
          margin-left: 1vw;
        }
      }

    }


  }

  .trial-back{
    text-align: center;
    margin-top: 32px;
    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }
    .btn-position{
      position: absolute;left: 28%;top: 50%;
    }

    .btn:hover {
      cursor: pointer;
    }
  }

  .trial-btn {
    text-align: center;
    margin-top: 32px;
    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }

    .btn:hover {
      cursor: pointer;
    }

    img {
      margin-left: 7px;
      padding-bottom: 3px;
    }

  }
  .trial-back {
    /*background: url("../../img/product/cloudDoc/bg_2.png") no-repeat top center;*/
    width: 100%;
    height: 224px;

    .trial-btn {
      margin-top: 80px;
      .btn {
        width: 290px;
        height: 64px;
        padding-top: 16px;
        border-radius: 28px;
        font-size: 24px;
        color: #2974E4;
        line-height: 32px;
        font-weight: 400;
      }
    }
  }
}

/* 2. 平板设备（大于等于576px）布局容器的宽度为540px */
@media screen and (min-width: 576px) {


  .text-overflow-cloudDoc{
    word-break: keep-all;
    /*white-space: nowrap;*/
    overflow: hidden;
    text-overflow:ellipsis;
  }

  .item-img-kexue{
    width: 11vw;
    height: 360px;
  }
  .relative-style{
    position: relative;
  }
  .absolute-style{
    position: absolute;top: 0px;
  }
  .cloud-doc {
    background: #FFFFFF;
    margin-bottom: 120px;

    .module-top {
      margin-top: 96px;

      .page-title {
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        color: #3764D7;
        line-height: 67px;
      }

      .product {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #3764D7;
        line-height: 42px;
        margin-top: 24px;
      }


    }

    .doc-information {
      background: url("../../img/product/cloudDoc/bg_1.png") no-repeat top center;
      width: 100%;
      margin-top: 159px;



      .doc-interface {
        float: unset;
        margin: auto;
        width: 732px;
        position: relative;
        bottom: 111px;
      }

      .doc-info {
        width: 542px;
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        float: unset;
        margin: auto;
        text-align: center;
        position: relative;
        bottom: 85px;
      }

      .doc-fun {
        font-size: 40px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 48px;
        text-align: center;
      }

      .scene-item {
        margin: auto;
        float: unset;
        text-align: justify;
        height: 320px;

        .introduce-img {

        }

        .title-right {
          padding-right: 63px;
        }

        .title-left {
          padding-left: 2vw;
        }

        .img-left {
          margin-right: 1vw;
        }

        .img-right {
          margin-left: 1vw;
        }
        .scene-introduce {
          padding-top: 2vw;

          .introduce-title {
            font-size: 24px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 32px;
            margin-bottom: 24px;
          }

          .introduce-des {
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
          }
        }
        .scene-white {
          .introduce-title {
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
          }
          .introduce-des {
            color: #414141;
          }
        }
      }

      .doc-scene {
        text-align: center;
        margin-top: 56px;

        .scene-item:first-child {
          margin-top: unset;
        }

        .scene-item {
          margin-top: 72px;
        }
      }

      .doc-scene-out {
        text-align: center;
        margin-top: 72px;
      }
    }

    .doc-security {
      margin-top: 120px;
      .security-title {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: #3764D7;
        line-height: 48px;
      }

      .security-des {
        margin: auto;
        float: unset;
        height: auto;

        .des-item:last-child {
          margin-right: unset;
        }

        .des-item {
          height: 376px;
          box-shadow: 0px 8px 17px 0px rgba(171, 171, 171, 0.5);
          border-radius: 22px;
          text-align: center;
          margin-right: 10vw;
          margin-top: 48px;
          .des-title {
            font-size: 24px;
            font-weight: 600;
            color: #3764D7;
            line-height: 32px;
            padding-top: 66px;
          }

          .security-item {
            text-align: center;


            .item:first-child {
              margin-top: 32px;
            }

            .item {
              font-size: 16px;
              font-weight: 400;
              color: #414141;
              line-height: 24px;
              margin-top: 24px;
            }
          }
        }
      }
    }

    .doc-case {
      margin-top: 120px;
      .case-title {
        font-size: 40px;
        font-weight: 500;
        color: #3764D7;
        line-height: 48px;
        text-align: center;
        margin-bottom: 48px;
      }
      .case-list {
        margin: auto;
        float: unset;
        height: auto;

        .case-item:last-child {
          margin-right: 0;
        }

        .case-item {
          text-align: center;
          margin-right: 8vw;
          height: auto;
          .item-content {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
            margin-top: 17px;
          }
        }
      }
    }

    .doc-manager {
      margin-top: 120px;
      .manager-title {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: #3764D7;
        line-height: 48px;
        margin-bottom: 48px;
      }

      .manager-list {
        margin: auto;
        float: unset;
        .item:hover {
          filter: brightness(50%);
          -webkit-filter: brightness(50%);
          cursor: pointer;

          .title {
            display: none;
          }

          .content {
            display: block;
          }
        }
        .item:last-child {
          margin-right: 0;
        }
        .item {
          /*margin-right: 36px;*/
          .title {
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 32px;
            text-align: center;
            //padding: 164px 56px;
            padding-top: 164px;
            display: block;
          }
          .content {
            display: none;
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 32px;
            padding: 75px 18px;
            text-align: justify;
          }
        }

        .item_1 {
          /*background: url("../../img/product/cloudDoc/kexue_1.png") no-repeat top center;*/
          height: 360px;
          width: 12vw;
        }

        .item_2 {
          /*background: url("../../img/product/cloudDoc/kexue_2.png") no-repeat top center;*/
          width: 12vw;
          height: 360px;
        }
        .item_3 {
          /*background: url("../../img/product/cloudDoc/kexue_3.png") no-repeat top center;*/
          width: 12vw;
          height: 360px;
        }

        .item_4 {
          /*background: url("../../img/product/cloudDoc/kexue_4.png") no-repeat top center;*/
          width: 12vw;
          height: 360px;
        }

        .item_5 {
          /*background: url("../../img/product/cloudDoc/kexue_5.png") no-repeat top center;*/
          width: 12vw;
          height: 360px;
        }
      }

    }


  }

  .trial-back{
    text-align: center;
    margin-top: 32px;
    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }
    .btn-position{
      position: absolute;left: 44%;top: 50%;
    }

    .btn:hover {
      cursor: pointer;
    }
  }

  .trial-btn {
    text-align: center;
    margin-top: 32px;
    .btn {
      border: 1px solid #3764D7;
      border-radius: 22px;
      width: 192px;
      height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #3764D7;
      line-height: 28px;
      float: unset;
      margin: auto;
      padding-top: 6px;
      background-color: #FFFFFF;
    }

    .btn:hover {
      cursor: pointer;
    }

    img {
      margin-left: 7px;
      padding-bottom: 3px;
    }

  }
  .trial-back {
    /*background: url("../../img/product/cloudDoc/bg_2.png") no-repeat top center;*/
    width: 100%;
    height: 224px;

    .trial-btn {
      margin-top: 80px;
      .btn {
        width: 290px;
        height: 64px;
        padding-top: 16px;
        border-radius: 28px;
        font-size: 24px;
        color: #2974E4;
        line-height: 32px;
        font-weight: 400;
      }
    }
  }
}

/*!* 3. 桌面显示器（大于等于768px）布局容器宽度为720px *!
@media screen and (min-width: 768px) {

}

!* 4. 大桌面显示器（大于等于992px）布局容器宽度为960px *!
@media screen and (min-width: 992px) {

}

!* 5. 超大桌面显示器（大于等于1200）布局容器宽度为1140 *!
@media screen and (min-width: 1200px) {

}*/

</style>
